// src/services/api.js

import axios from "axios";
import config from "../configs/config";

const API_URL = `${config.API_URL}`;

const api = axios.create({
	baseURL: API_URL,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

api.interceptors.request.use((config) => {
	const token = localStorage.getItem("token");
	if (token) {
		config.headers["Authorization"] = `Bearer ${token}`;
	}
	return config;
});

export const register = (name, email, password) => {
	return api.post("/register", { name, email, password });
};

export const login = (email, password) => {
	return api.post("/login", { email, password });
};

export const logout = () => {
	return api.post("/logout");
};

export const getUser = () => {
	return api.get("/user");
};

export default api;
