// src/contexts/AuthContext.js

import React, { createContext, useState, useContext, useEffect } from "react";
import {
	login as apiLogin,
	logout as apiLogout,
	getUser,
} from "../services/api";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const token = localStorage.getItem("token");
		if (token) {
			getUser()
				.then((response) => {
					setUser(response.data.user);
					setLoading(false);
				})
				.catch(() => {
					localStorage.removeItem("token");
					setLoading(false);
				});
		} else {
			setLoading(false);
		}
	}, []);

	const login = async (email, password) => {
		const response = await apiLogin(email, password);
		localStorage.setItem("token", response.data.access_token);
		setUser(response.data.user);
	};

	const logout = async () => {
		await apiLogout();
		localStorage.removeItem("token");
		setUser(null);
	};

	return (
		<AuthContext.Provider value={{ user, login, logout, loading }}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuth = () => useContext(AuthContext);
