// src/components/Profile.js

import React from "react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

const Profile = () => {
	const { user, logout } = useAuth();
	const navigate = useNavigate();

	const handleLogout = async () => {
		try {
			await logout();
			navigate("/login");
		} catch (error) {
			console.error("Failed to log out", error);
		}
	};

	if (!user) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			<h2>Profile</h2>
			<p>Name: {user.name}</p>
			<p>Email: {user.email}</p>
			<button onClick={handleLogout}>Logout</button>
		</div>
	);
};

export default Profile;
