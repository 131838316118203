// src/config.js

const config = {
	API_URL: process.env.REACT_APP_API_URL || "http://localhost:8000/api",
};

// You can add more configuration variables here as needed
// For example:
// DEBUG: process.env.REACT_APP_DEBUG === 'true',
// VERSION: process.env.REACT_APP_VERSION,

export default config;
